:root {
    --funeral-primary: #4A5568;
    --funeral-accent: #718096;
    --funeral-light: #EDF2F7;
}

.funeral-description {
    background: linear-gradient(to right, var(--funeral-light), white);
    padding: 4rem;
    border-radius: 20px;
    margin: 4rem 0;
}

.funeral-contact {
    margin-top: 2rem;
    background-color: var(--funeral-primary);
    border: 1px solid var(--funeral-primary);
    color: white;
    transition: all 0.3s ease;
}

.funeral-contact:hover {
    background-color: white;
    color: var(--funeral-primary);
}

.services-section {
    padding: 4rem 0;
}

.services-section h2 {
    font-family: var(--heading-font);
    font-size: 2.2rem;
    color: var(--funeral-primary);
    text-align: center;
    margin-bottom: 3rem;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.service-card {
    background: white;
    padding: 2.5rem;
    border-radius: 15px;
    border: 1px solid var(--funeral-light);
    transition: all 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 30px rgba(0,0,0,0.05);
}

.service-icon {
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.service-card h3 {
    font-family: var(--heading-font);
    font-size: 1.4rem;
    color: var(--funeral-primary);
    margin-bottom: 1rem;
}

.service-card p {
    font-size: 1rem;
    line-height: 1.6;
    color: var(--funeral-accent);
}

.contact-button {
    display: inline-flex;
    align-items: center;
    padding: 1rem 2rem;
    background-color: var(--funeral-primary);
    color: white;
    border: 1px solid var(--funeral-primary);
    border-radius: 30px;
    text-decoration: none;
    font-family: var(--body-font);
    font-size: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
}

.contact-button:hover {
    background-color: transparent;
    color: var(--funeral-primary);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

/* Funeral-specific button styles */
/* Make the button styles more specific */
.funeral-page .contact-button,
.funeral-page .contact-button.funeral-contact {
    display: inline-flex;
    align-items: center;
    padding: 1rem 2rem;
    background-color: #4A5568 !important; /* Using direct color temporarily for testing */
    color: white !important;
    border: 1px solid #4A5568;
    border-radius: 30px;
    text-decoration: none;
    font-family: var(--body-font);
    font-size: 1rem;
    transition: all 0.3s ease;
    cursor: pointer;
    margin-top: 2rem;
}

.funeral-page .contact-button:hover,
.funeral-page .contact-button.funeral-contact:hover {
    background-color: transparent !important;
    color: #4A5568 !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.funeral-page .button-arrow {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
}

.funeral-page .contact-button:hover .button-arrow {
    transform: translateX(5px);
}

@media screen and (max-width: 768px) {
    .services-grid {
        grid-template-columns: 1fr;
    }
    
    .funeral-description {
        padding: 2rem;
    }
}