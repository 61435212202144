.footer {
    background-color: white;
    padding: 4rem 2rem 2rem;
    color: var(--text-color);
    border-top: 1px solid var(--light-blue);
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 3rem;
}

.footer-section h3 {
    font-family: var(--heading-font);
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.footer-section p {
    font-family: var(--body-font);
    margin-bottom: 1rem;
}

.footer-section h4 {
    font-family: var(--accent-font);
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: var(--dark-blue);
}

.footer-section a {
    display: block;
    color: var(--text-color);
    text-decoration: none;
    margin-bottom: 0.8rem;
    transition: color 0.3s ease;
}

.footer-section a:hover {
    color: var(--dark-blue);
}

.social-links {
    display: flex;
    gap: 1.5rem;
    margin-top: 1.5rem;
}

.social-links a {
    color: var(--dark-blue);
    font-size: 1.5rem;
    transition: transform 0.3s ease;
}

.social-links a:hover {
    transform: translateY(-3px);
}

.footer-bottom {
    max-width: 1200px;
    margin: 3rem auto 0;
    padding-top: 2rem;
    text-align: center;
    border-top: 1px solid var(--light-blue);
    font-size: 0.9rem;
    opacity: 0.8;
}

@media screen and (max-width: 768px) {
    .footer-content {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }

    .footer-section:first-child {
        grid-column: 1 / -1;
        text-align: center;
    }

    .social-links {
        justify-content: center;
    }
}

@media screen and (max-width: 480px) {
    .footer-content {
        grid-template-columns: 1fr;
        text-align: center;
    }

    .footer {
        padding: 3rem 1rem 1rem;
    }
}