.shop-container {
    max-width: 1200px;
    margin: 180px auto 40px;
    padding: 0 20px;
}

.shop-container h1 {
    font-family: var(--heading-font);
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.product-card {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    text-align: center;
    height: 320px;
    display: flex;
    flex-direction: column;
}

.product-card a {
    text-decoration: none;
    color: #6B7B99;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.product-card a:visited {
    color: #6B7B99;
}

.product-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 4px;
    margin-bottom: 10px;
}

.product-card h3 {
    margin: 10px 0;
    font-size: 1.1em;
    transition: color 0.3s ease;
}

.product-card a:hover h3 {
    color: #5A6A88;
}

.product-card .price {
    font-weight: bold;
    color: #6B7B99;
    margin: 10px 0;
}

.cart-summary {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
}

.product-card button {
    background-color: #6B7B99;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: auto;
}

.product-card button:hover {
    background-color: #5A6A88;
}

.product-card button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.product-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 8px;
    background-color: #f5f5f5; /* Light grey background while loading */
}

/* Placeholder style */
.product-image[src='/placeholder-image.jpg'] {
    opacity: 0.5;
}

.cart-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    background: #f8f9fa;
    padding: 15px 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.cart-info p {
    font-size: 1.1rem;
    color: #333;
}

.go-to-cart-btn {
    background-color: #6B7B99;
    color: white;
    padding: 12px 25px;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    font-family: var(--body-font);
}

.go-to-cart-btn:hover {
    background-color: #5A6A88;
    transform: translateY(-2px);
}