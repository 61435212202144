.product-detail-container {
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
    margin-top: 180px;
}

.product-main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-bottom: 40px;
}

.product-description {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
}

.product-description h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
}

.variation-selector {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.variation-selector select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    min-width: 200px;
    font-size: 1rem;
}

.variation-selector option:disabled {
    color: #999;
    font-style: italic;
}

.product-detail-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.product-detail-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.product-detail-info h1 {
    font-size: 2.5rem;
    margin: 0;
    color: #6B7B99;  /* Matching the bluebell theme */
}

.price {
    font-size: 1.8rem;
    font-weight: bold;
    color: #333;
    margin: 15px 0;
}

.description {
    margin-top: 16px;
    line-height: 1.6;
    color: #333;
    white-space: pre-line;  /* This preserves line breaks */
}

.quantity-selector {
    display: flex;
    align-items: center;
    gap: 10px;
}

.quantity-selector select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.add-to-cart-btn {
    background-color: #6B7B99;  /* Changed to bluebell color */
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
    background-color: #5A6A88;  /* Slightly darker shade for hover */
}

.add-to-cart-btn:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}

.product-meta {
    color: #666;
    font-size: 0.9rem;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 8px;
    margin-top: 20px;
}

.product-meta .description {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
    margin-top: 15px;
    border-top: 1px solid #ddd;
    padding-top: 15px;
}

@media (max-width: 768px) {
    .product-detail-container {
        padding: 15px;
        margin-top: 100px;
    }

    .product-main {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    /* Title Section */
    .product-detail-info h1 {
        font-size: 24px;
        margin: 0;
        text-align: left;
        color: #333;
    }

    /* Price */
    .price {
        font-size: 20px;
        font-weight: 600;
        margin: 8px 0 16px;
        text-align: left;
    }

    /* Image */
    .product-detail-image {
        margin: 16px auto;
        width: 90%;
        max-width: 500px;
        display: flex;
        justify-content: center;
    }

    .product-detail-image img {
        width: 100%;
        height: auto;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0,0,0,0.05);
        border: 1px solid #eee;
        object-fit: contain;
    }

    /* Selectors */
    .variation-selector,
    .quantity-selector {
        margin: 16px 0;
    }

    .variation-selector select,
    .quantity-selector select {
        width: 100%;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 8px;
        font-size: 16px;
        background-color: white;
    }

    .variation-selector label,
    .quantity-selector label {
        display: block;
        margin-bottom: 8px;
        font-weight: 500;
        color: #333;
    }

    /* Add to Cart Button */
    .add-to-cart-btn {
        width: 100%;
        padding: 16px;
        margin: 24px 0;
        border-radius: 8px;
        font-size: 16px;
        font-weight: 600;
    }

    /* Product Meta Info */
    .product-meta {
        margin-top: 32px;
        padding: 24px 16px;
        background-color: #f9f9f9;
        border-radius: 8px;
    }

    .product-meta p {
        margin: 8px 0;
        color: #666;
    }

    .description {
        margin-top: 16px;
        line-height: 1.6;
        color: #333;
    }
}