/* Workshop-specific variables */
.workshops-page {
    --workshop-primary: #557A95;
    --workshop-secondary: #7395AE;
    --workshop-accent: #E8DDB5;
    --workshop-light: #F7F9FB;
    --workshop-dark: #2D4654;
}

/* Base Layout */
.workshops-page {
    min-height: calc(100vh - 70px);
    padding: 120px 0 0 0;
    background-color: white;
}

.workshops-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
    animation: fadeIn 1s ease-out;
}

/* Header Styles */
.workshops-header {
    text-align: center;
    margin-bottom: 4rem;
    padding: 4rem 0;
    background: linear-gradient(135deg, white, var(--workshop-light));
    border-radius: 20px;
}

.workshops-header h1 {
    font-family: var(--heading-font);
    font-size: clamp(3rem, 5vw, 4rem);
    color: var(--workshop-dark);
    font-weight: 300;
    margin-bottom: 1rem;
}

.workshops-header h2 {
    font-family: var(--accent-font);
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    color: var(--workshop-secondary);
    font-weight: 300;
    font-style: italic;
}

/* Intro Section */
.workshops-intro {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
    margin: 6rem 0;
    padding: 4rem;
    background: linear-gradient(45deg, var(--workshop-light), white);
    border-radius: 20px;
}

.intro-text {
    font-family: var(--body-font);
    line-height: 1.8;
}

.intro-text p {
    margin-bottom: 2rem;
    color: var(--workshop-dark);
}

.intro-image {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.intro-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

/* Upcoming Workshops Section */
.upcoming-workshops {
    margin: 6rem 0;
    padding: 4rem 0;
}

.upcoming-workshops h2 {
    text-align: center;
    font-family: var(--heading-font);
    font-size: 2.5rem;
    color: var(--workshop-dark);
    margin-bottom: 3rem;
}

.workshops-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
    padding: 0 1rem;
}

.workshop-card {
    background: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0,0,0,0.05);
    transition: transform 0.3s ease;
}

.workshop-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.workshop-image {
    height: 200px;
    overflow: hidden;
}

.workshop-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.workshop-details {
    padding: 2rem;
}

.workshop-details h3 {
    font-family: var(--heading-font);
    font-size: 1.4rem;
    color: var(--workshop-dark);
    margin-bottom: 1rem;
}

.workshop-date {
    color: var(--workshop-secondary);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

.workshop-price {
    font-size: 1.2rem;
    color: var(--workshop-dark);
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.workshop-spots {
    color: #E67E22;
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

/* Gallery Section */
.workshop-gallery {
    margin: 6rem 0;
    padding: 4rem;
    background: linear-gradient(135deg, white, var(--workshop-light));
    border-radius: 20px;
}

.gallery-tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;
    flex-wrap: wrap;
}

.gallery-tab {
    padding: 0.8rem 2rem;
    border: none;
    background: white;
    border-radius: 30px;
    font-family: var(--body-font);
    color: var(--workshop-dark);
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.gallery-tab:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.gallery-tab.active {
    background: var(--workshop-primary);
    color: white;
}

.gallery-content {
    text-align: center;
    margin-bottom: 3rem;
}

.gallery-content h3 {
    font-family: var(--heading-font);
    font-size: 2rem;
    color: var(--workshop-dark);
    margin-bottom: 0.5rem;
}

.gallery-date {
    color: var(--workshop-secondary);
    margin-bottom: 1rem;
}

.gallery-description {
    max-width: 600px;
    margin: 0 auto 2rem;
    color: var(--workshop-dark);
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.gallery-item {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    aspect-ratio: 1;
    cursor: pointer;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.gallery-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
    opacity: 1;
}

.gallery-overlay span {
    color: white;
    background: rgba(0,0,0,0.5);
    padding: 0.5rem 1rem;
    border-radius: 20px;
}

/* Buttons */
.workshop-contact-button,
.book-button,
.contact-button {
    display: inline-flex;
    align-items: center;
    padding: 1rem 2rem;
    background: var(--workshop-primary);
    color: white;
    border: none;
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease;
    cursor: pointer;
}

.workshop-contact-button:hover,
.book-button:hover,
.contact-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
    background: var(--workshop-secondary);
}

/* Disabled state */
.book-button:disabled {
    background: #cccccc;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.book-button:disabled:hover {
    transform: none;
    box-shadow: none;
    background: #cccccc;
}

.button-arrow {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
}

.workshop-contact-button:hover .button-arrow,
.contact-button:hover .button-arrow {
    transform: translateX(5px);
}

/* Contact Section */
.workshop-contact {
    margin: 6rem 0;
    padding: 4rem;
    text-align: center;
    background: linear-gradient(45deg, var(--workshop-light), white);
    border-radius: 20px;
}

.contact-content {
    max-width: 600px;
    margin: 0 auto;
}

.contact-content h2 {
    font-family: var(--heading-font);
    font-size: 2.5rem;
    color: var(--workshop-dark);
    margin-bottom: 1rem;
}

.contact-content p {
    margin-bottom: 2rem;
    color: var(--workshop-secondary);
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
    .gallery-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .workshops-intro {
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 2rem;
    }

    .gallery-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .gallery-tab {
        padding: 0.6rem 1.5rem;
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 480px) {
    .workshops-container {
        padding: 0 1rem;
    }

    .gallery-grid {
        grid-template-columns: 1fr;
    }

    .workshop-card {
        margin: 0 1rem;
    }
}
