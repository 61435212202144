.admin-gallery-container {
    max-width: 1200px;
    margin: 120px auto 40px;
    padding: 0 20px;
}

.create-album-section,
.upload-images-section {
    margin-bottom: 40px;
}

.create-album-section h2,
.upload-images-section h2 {
    font-family: var(--heading-font);
    margin-bottom: 10px;
}

.create-album-section form,
.upload-images-section form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.create-album-section input,
.upload-images-section select,
.upload-images-section input[type="file"],
.create-album-section textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
}

.create-album-section button,
.upload-images-section button {
    padding: 10px 20px;
    background-color: #6B7B99;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-album-section button:hover,
.upload-images-section button:hover {
    background-color: #5A6A88;
} 