.checkout-container {
    padding-top: 100px;
    max-width: 1200px;
    margin: 0 auto;
    min-height: 100vh;
    z-index: 1;
    display: flex;
    justify-content: center;
}

.error-message {
    background-color: #f8d7da;
    color: #721c24;
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #f5c6cb;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
}

.error-message .place-order-btn {
    margin-top: 20px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
}

/* Loading state */
.checkout-container .loading {
    text-align: center;
    padding: 40px;
    font-size: 18px;
    color: #666;
}

.place-order-btn {
    display: block;
    width: 100%;
    padding: 12px;
    background-color: #0a4d3c;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    margin-top: 20px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.place-order-btn:hover {
    background-color: #083d30;
}

@media (max-width: 768px) {
    .checkout-container {
        margin: 100px 20px 20px;
        padding: 15px;
    }
}

.variation-name {
    font-size: 0.9em;
    color: #666;
    font-weight: normal;
}

.item-details h3 {
    margin-bottom: 8px;
}