.cart-container {
    max-width: 1200px;
    margin: 150px auto 40px;
    padding: 0 20px;
}

.cart-items {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.cart-item {
    display: grid;
    grid-template-columns: 100px 1fr auto auto;
    gap: 20px;
    align-items: center;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
}

.cart-item img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
}

.item-details h3 {
    margin: 0 0 10px 0;
}

.quantity-controls {
    display: flex;
    gap: 10px;
    align-items: center;
}

.quantity-controls select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ddd;
}

.quantity-controls button {
    padding: 5px 10px;
    background-color: #ff4444;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.item-total {
    font-weight: bold;
    font-size: 1.1rem;
}

.cart-summary {
    margin-top: 40px;
    text-align: right;
}

.checkout-btn {
    background-color: #6B7B99;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    margin-top: 20px;
}

.checkout-btn:hover {
    background-color: #5A6A88;
}

.cart-buttons {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    margin-top: 20px;
}

.cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.empty-cart-btn {
    background-color: #ff4444;
    color: white;
    padding: 8px 15px;
    border: none;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
}

.empty-cart-btn:hover {
    background-color: #ff2020;
}

@media (max-width: 768px) {
    .cart-container {
        margin: 100px auto 40px;
        padding: 0 20px;
    }

    .cart-item {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding: 15px;
        align-items: center;
    }

    .cart-item img {
        width: 80px;
        height: 80px;
        object-fit: cover;
        border-radius: 4px;
    }

    .item-details {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        overflow: hidden;
    }

    .item-details h3 {
        font-size: 1rem;
        margin: 0;
        color: #333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .item-details p {
        margin: 0;
        font-size: 0.9rem;
        color: #666;
    }

    .quantity-controls {
        display: flex;
        gap: 5px;
        margin-top: 5px;
    }

    .item-total {
        font-weight: bold;
        font-size: 1rem;
        margin-left: auto;
        white-space: nowrap;
    }

    .cart-summary {
        text-align: center;
        margin-top: 20px;
    }

    .cart-buttons {
        justify-content: center;
    }
}