.order-confirmation-container {
    max-width: 800px;
    margin: 120px auto 40px;
    padding: 20px;
}

.order-confirmation-content {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
}

.order-confirmation-content h1 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
}

.order-details {
    margin-top: 20px;
}

.order-details h2 {
    color: #444;
    margin-bottom: 20px;
}

.confirmation-message {
    color: #666;
    margin-bottom: 30px;
    line-height: 1.6;
}

.order-summary {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 6px;
    margin-bottom: 30px;
}

.order-summary h3 {
    color: #444;
    margin-bottom: 15px;
}

.items-list {
    margin-bottom: 20px;
}

.order-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.item-name {
    flex: 2;
}

.item-quantity {
    flex: 1;
    text-align: center;
}

.item-price {
    flex: 1;
    text-align: right;
}

.order-total {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    font-weight: bold;
    border-top: 2px solid #eee;
}

.delivery-details {
    margin-top: 30px;
}

.delivery-details h3 {
    color: #444;
    margin-bottom: 15px;
}

.delivery-details p {
    margin: 5px 0;
    color: #666;
}

.actions {
    margin-top: 30px;
    text-align: center;
}

.continue-shopping {
    background-color: #4CAF50;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.continue-shopping:hover {
    background-color: #45a049;
}

.loading, .error {
    text-align: center;
    padding: 40px;
    color: #666;
}

.error {
    color: #d32f2f;
} 