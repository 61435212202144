.gallery-container {
    max-width: 1200px;
    margin: 180px auto 40px;
    padding: 0 20px;
}

.gallery-container h1 {
    font-family: var(--heading-font);
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.album-header {
    display: flex;
    gap: 2rem;
    margin-bottom: 2rem;
    align-items: flex-start;
}

.album-header .featured-image-container {
    flex: 0 0 400px;
    height: 400px;
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
}

.album-header .featured-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.album-info-section {
    flex: 1;
    padding: 1rem 0;
}

.album-info-section h2 {
    margin-top: 0;
    margin-bottom: 1rem;
}

.album-description {
    color: #666;
    line-height: 1.6;
}

.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
    margin-top: 2rem;
}

.gallery-image {
    position: relative;
    padding-bottom: 100%;
    cursor: pointer;
    overflow: hidden;
}

.gallery-image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-image:hover img {
    transform: scale(1.05);
}

.albums-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    margin-top: 30px;
}

.album-card {
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.album-card:hover {
    transform: translateY(-5px);
}

.album-card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.album-info {
    padding: 15px;
    background: white;
}

.album-info h3 {
    margin: 0;
    font-family: var(--heading-font);
}

.album-info p {
    margin: 5px 0 0;
    color: #666;
}

.back-button {
    margin-bottom: 20px;
    padding: 10px 20px;
    background: var(--light-blue);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--body-font);
}

.back-button:hover {
    background: var(--dark-blue);
    color: white;
}

.gallery-image video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.video-overlay svg {
    width: 40px;
    height: 40px;
    fill: white;
    filter: drop-shadow(0 2px 4px rgba(0,0,0,0.3));
}

.video-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
} 