:root {
    --primary-blue: #a7d7ff;
    --secondary-blue: #d4ebff;
    --dark-blue: #5eb3ff;
    --light-blue: #f0f7ff;
    --text-color: #4a5568;
    --heading-font: 'Italiana', serif;
    --body-font: 'Quicksand', sans-serif;
    --accent-font: 'Playfair Display', serif;
    --flower-image: url('../assets/flower.png');
}

/* Base Styles */
body {
    font-family: var(--body-font);
}

.home {
    min-height: 100vh;
    padding-top: 70px;
    background-color: #fff;
}

/* Hero Section */
.hero-section {
    min-height: 90vh;
    background: linear-gradient(
        rgba(167, 215, 255, 0.1),
        rgba(167, 215, 255, 0.05)
    );
    display: flex;
    align-items: center;
    padding: 4rem 2rem;
    overflow: hidden;
}

.hero-content {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
    animation: fadeIn 1.5s ease-out;
}

.title-group {
    margin-bottom: 2rem;
}

.title-group h1 {
    font-family: var(--heading-font);
    font-size: clamp(3.5rem, 8vw, 4.5rem);
    color: #4a5568;
    font-weight: 300;
    letter-spacing: 2px;
    line-height: 1;
    margin: 0;
}

.title-group h2 {
    font-family: var(--accent-font);
    font-size: clamp(1.5rem, 3vw, 2rem);
    color: #4a5568;
    font-weight: 300;
    font-style: italic;
    letter-spacing: 1px;
    margin: 0.5rem 0 0 0;
    opacity: 0.9;
}

.hero-text {
    text-align: left;
    padding-right: 2rem;
}

.hero-description {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.hero-description p {
    font-family: var(--body-font);
    font-size: clamp(1rem, 1.2vw, 1.1rem);
    line-height: 1.8;
    color: var(--text-color);
    margin: 0;
}

.delivery-note {
    font-style: italic;
    color: var(--dark-blue) !important;
    font-size: clamp(0.9rem, 1.1vw, 1rem) !important;
    margin-top: 0.5rem;
}

.hero-image-frame {
    position: relative;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.arch-decoration {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.arch-inner {
    position: relative;
    width: 90%;
    height: 90%;
    margin: 5% auto;
    border-radius: 30px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.arch-inner::before {
    content: '';
    position: absolute;
    top: -60%;
    left: -20%;
    right: -20%;
    bottom: 40%;
    background: 
        radial-gradient(
            ellipse at center,
            var(--secondary-blue) 0%,
            transparent 70%
        );
    z-index: 1;
}

.arch-inner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 2;
}

.arch-flowers {
    position: absolute;
    width: 100px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.8;
}

.top-left {
    top: -20px;
    left: -20px;
    background-image: var(--flower-image);
    transform: rotate(-45deg);
}

.top-right {
    top: -20px;
    right: -20px;
    background-image: var(--flower-image);
    transform: rotate(45deg);
}

.bottom-left {
    bottom: -20px;
    left: -20px;
    background-image: var(--flower-image);
    transform: rotate(-135deg);
}

.bottom-right {
    bottom: -20px;
    right: -20px;
    background-image: var(--flower-image);
    transform: rotate(135deg);
}

/* Gallery Section */
.gallery-section {
    padding: 6rem 2rem;
    background-color: white;
}

.gallery-section h2 {
    font-family: var(--heading-font);
    text-align: center;
    margin-bottom: 3rem;
    font-size: 2.5rem;
    color: var(--text-color);
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    max-width: 1200px;
    margin: 0 auto;
}

.gallery-item {
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    aspect-ratio: 1;
    cursor: pointer;
}

.gallery-item.large {
    grid-column: span 2;
    grid-row: span 2;
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.view-more-button {
    display: block;
    max-width: 200px;
    margin: 3rem auto;
    background-color: var(--dark-blue);
    color: white;
    text-align: center;
}

.view-more-button:hover {
    transform: translateY(-2px);
}

/* Shop Preview Section */
.shop-preview {
    padding: 6rem 2rem;
    background: linear-gradient(to bottom right, var(--light-blue), white);
}

.shop-content {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

.shop-content h2 {
    font-family: var(--heading-font);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: var(--text-color);
}

.shop-features {
    display: flex;
    justify-content: center;
    gap: 3rem;
    margin: 2rem 0;
}

.feature {
    text-align: center;
}

.feature-icon {
    font-size: 2rem;
    margin-bottom: 0.5rem;
}

.shop-button {
    display: inline-block;
    padding: 1rem 2.5rem;
    background-color: var(--dark-blue);
    color: white;
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 2rem;
    font-family: var(--body-font);
    font-size: 1.1rem;
    text-align: center;
}

.shop-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    background-color: #4a9eff;
}

/* Info Section */
.info-section {
    padding: 6rem 2rem;
    background-color: white;
}

.info-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    max-width: 1200px;
    margin: 0 auto;
}

.hours-card, .contact-card {
    padding: 3rem;
    border-radius: 20px;
    background-color: var(--light-blue);
    text-align: center;
}

.hours-card h3, .contact-card h3 {
    font-family: var(--heading-font);
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--text-color);
}

.hours-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.hours-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}

/* Workshop Section */
.workshop-section {
    padding: 6rem 2rem;
    background-color: var(--light-blue);
    position: relative;
    overflow: hidden;
}

.workshop-content {
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 2;
}

.workshop-content h2 {
    font-family: var(--heading-font);
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    color: var(--text-color);
}

.workshop-features {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin: 3rem 0;
}

.workshop-feature {
    padding: 2rem;
    background-color: white;
    border-radius: 15px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
}

.workshop-feature h4 {
    font-family: var(--accent-font);
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.workshop-button {
    display: inline-block;
    padding: 1rem 2.5rem;
    background-color: white;
    color: var(--dark-blue);
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 2rem;
    font-family: var(--body-font);
    font-size: 1.1rem;
    text-align: center;
}

.workshop-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    background-color: #4a9eff;
}

/* Contact Banner */
.contact-banner {
    position: relative;
    padding: 6rem 2rem;
    background-color: var(--primary-blue);
    overflow: hidden;
}

.banner-waves {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: 
        radial-gradient(circle at 20% 50%, var(--secondary-blue) 0%, transparent 50%),
        radial-gradient(circle at 80% 50%, var(--secondary-blue) 0%, transparent 50%);
}

.banner-content {
    position: relative;
    z-index: 2;
    text-align: center;
    color: var(--text-color);
}

.banner-content h2 {
    font-family: var(--heading-font);
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.contact-button {
    display: inline-block;
    padding: 1rem 2.5rem;
    background-color: white;
    color: var(--dark-blue);
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 2rem;
    font-family: var(--body-font);
    font-size: 1.1rem;
    text-align: center;
}

.contact-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    background-color: var(--light-blue);
}

/* Update the contact button in the contact-card */
.contact-card .contact-button {
    display: inline-block;
    padding: 1rem 2.5rem;
    background-color: var(--dark-blue);
    color: white;
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 2rem;
    font-family: var(--body-font);
    font-size: 1.1rem;
}

.contact-card .contact-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    background-color: #4a9eff;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
    .hero-content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .hero-text {
        text-align: center;
        padding-right: 0;
    }

    .hero-image-frame {
        height: 400px;
    }

    .arch-decoration {
        max-width: 350px;
    }

    .arch-flowers {
        width: 60px;
        height: 60px;
    }

    .gallery-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .gallery-item.large {
        grid-column: auto;
        grid-row: auto;
    }

    .shop-features {
        flex-direction: column;
        gap: 1.5rem;
    }

    .info-grid {
        grid-template-columns: 1fr;
    }

    .workshop-features {
        grid-template-columns: 1fr;
    }

    .hero-section,
    .gallery-section,
    .shop-preview,
    .info-section,
    .workshop-section,
    .contact-banner {
        padding: 4rem 1rem;
    }
}

/* Common button styles */
.shop-button,
.contact-button,
.workshop-button,
.view-more-button {
    display: inline-block;
    padding: 1rem 2.5rem;
    background-color: var(--dark-blue);
    color: white;
    border-radius: 30px;
    text-decoration: none;
    transition: all 0.3s ease;
    margin-top: 2rem;
    font-family: var(--body-font);
    font-size: 1.1rem;
    text-align: center;
}

.shop-button:hover,
.contact-button:hover,
.workshop-button:hover,
.view-more-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    background-color: #4a9eff;
}

/* Special styling for the View Full Gallery button */
.view-more-button {
    display: block;
    max-width: 200px;
    margin: 3rem auto;
    background-color: var(--dark-blue);
    color: white;
    text-align: center;
}

/* Special styling for contact button in blue sections */
.contact-banner .contact-button,
.banner-content .contact-button {
    background-color: white;
    color: var(--dark-blue);
}

.contact-banner .contact-button:hover,
.banner-content .contact-button:hover {
    background-color: var(--light-blue);
    color: var(--dark-blue);
}