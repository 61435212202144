:root {
    --wedding-primary: #6B9AC4;
    --wedding-primary-dark: #4A5568;  /* Much darker blue for better contrast */
    --wedding-light: #EBF2F8;
    --wedding-accent: #8BA5C4;
}
/* Add at root level */
html, body {
    max-width: 100%;
    overflow-x: hidden;
}

/* Base Layout */
.service-page {
    min-height: calc(100vh - 70px);
    padding: 120px 0 0 0;
    background-color: white;
    max-width: 100%;
    overflow-x: hidden;
}

.service-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 2rem;
    animation: fadeIn 1s ease-out;
    max-width: 100%;
    overflow-x: hidden;
}

/* Header Section */
.service-header {
    text-align: center;
    margin-bottom: 4rem;
    padding: 4rem 0;
    background: linear-gradient(135deg, white, var(--light-blue));
    border-radius: 20px;
}

.service-header h1 {
    font-family: var(--heading-font);
    font-size: clamp(3rem, 5vw, 4rem);
    color: var(--text-color);
    font-weight: 300;
    margin-bottom: 1rem;
}

.service-header h2 {
    font-family: var(--accent-font);
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    color: var(--text-color);
    font-weight: 300;
    font-style: italic;
    opacity: 0.9;
}

/* Description Section */
.service-description {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    align-items: center;
    margin: 6rem 0;
    padding: 4rem;
    background: linear-gradient(45deg, var(--light-blue), white);
    border-radius: 20px;
}

.description-text {
    font-family: var(--body-font);
    line-height: 1.8;
}

.description-text p {
    margin-bottom: 1.5rem;
    color: var(--text-color);
}

.description-image {
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
}

.description-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

/* Services Grid */
.services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns */
    gap: 1.5rem;
    justify-content: center; /* Centers the grid */
    margin: 2rem auto; /* Centers the grid horizontally */
    max-width: 1000px; /* Adjust width as needed */
}

.service-card {
    max-width: 500px; /* Ensures cards are not too wide */
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.05);
    transition: all 0.3s ease;
    }

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.service-icon {
    font-size: 2rem;
    margin-bottom: 1rem;
}

.service-card h3 {
    font-family: var(--heading-font);
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.service-card p {
    font-size: 0.9rem;
    line-height: 1.6;
    color: var(--text-color);
}

/* Gallery Section */
.gallery-section {
    margin: 6rem 0;
    padding: 4rem 0;
    background: linear-gradient(225deg, white, var(--light-blue));
    border-radius: 20px;
}

.gallery-title {
    text-align: center;
    font-family: var(--heading-font);
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: var(--text-color);
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    padding: 0 2rem;
}

/* Gallery Items */
.gallery-item {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    aspect-ratio: 1;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.gallery-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.gallery-item:hover img {
    transform: scale(1.05);
}

.gallery-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0,0,0,0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.gallery-item:hover .gallery-overlay {
    opacity: 1;
}

.gallery-overlay span {
    color: white;
    font-family: var(--body-font);
    background: rgba(0,0,0,0.5);
    padding: 0.5rem 1rem;
    border-radius: 20px;
}

/* Testimonials Section */
.wave-testimonials {
    position: relative;
    margin: 8rem 0;
    padding: 6rem 0;
    background: linear-gradient(315deg, var(--light-blue), white);
    border-radius: 20px;
}

.testimonials-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
}

.testimonials-section h2 {
    text-align: center;
    font-family: var(--heading-font);
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: var(--text-color);
}

.testimonials-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.testimonial-card {
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.05);
    transition: transform 0.3s ease;
}

.testimonial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.quote-mark {
    font-size: 2.5rem;
    color: var(--primary-blue);
    line-height: 1;
    margin-bottom: 1rem;
}

.testimonial-text {
    font-style: italic;
    margin-bottom: 1.5rem;
    color: var(--text-color);
}

.testimonial-author {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.author-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.author-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.timeline {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.timeline-item {
    flex: 1;
    position: relative;
    background: white;
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0,0,0,0.05);
    transition: transform 0.3s ease;
}

.timeline-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.timeline-number {
    width: 40px;
    height: 40px;
    background: var(--primary-blue);
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1rem;
    font-size: 1.2rem;
}

.timeline-content h3 {
    font-family: var(--heading-font);
    margin-bottom: 1rem;
    color: var(--text-color);
}

.timeline-content p {
    font-size: 0.9rem;
    line-height: 1.6;
    color: var(--text-color);
}

/* Seasonal Section */
.seasonal-section {
    margin: 8rem 0;
    padding: 6rem 2rem;
    background: linear-gradient(135deg, white, var(--light-blue));
    border-radius: 20px;
    text-align: center;
}

.seasonal-section h2 {
    font-family: var(--heading-font);
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: var(--text-color);
}

.seasons-tabs {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 3rem;
}

.season-tab {
    padding: 0.8rem 2rem;
    border: none;
    background: white;
    border-radius: 30px;
    font-family: var(--body-font);
    font-size: 1rem;
    color: var(--text-color);
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 10px rgba(0,0,0,0.05);
}

.season-tab:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
}

.season-tab.active {
    background: var(--primary-blue);
    color: white;
}

.season-content {
    padding: 2rem 0;
}

.season-flowers {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.flower-item {
    background: white;
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0,0,0,0.05);
    transition: transform 0.3s ease;
}

.flower-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0,0,0,0.1);
}

.flower-item img {
    width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    border-radius: 15px 15px 0 0;
}

.flower-item h4 {
    padding: 1rem;
    font-family: var(--heading-font);
    font-size: 1.1rem;
    color: var(--text-color);
    text-align: center;
}

/* Contact Section */
.contact-section {
    margin: 8rem 0;
    padding: 6rem 2rem;
    background: linear-gradient(225deg, white, var(--wedding-light));
    border-radius: 20px;
    text-align: center;
}

.contact-content {
    max-width: 600px;
    margin: 0 auto;
}

.contact-content h2 {
    font-family: var(--heading-font);
    font-size: 2.5rem;
    margin-bottom: 1rem;
    color: var(--text-color);
}

.contact-content p {
    margin-bottom: 2.5rem;
    color: var(--text-color);
    font-size: 1.1rem;
}

.wedding-contact {
    display: inline-flex;
    align-items: center;
    padding: 1.2rem 3rem;
    background-color: #4A5568;
    color: white;
    border: none;
    border-radius: 50px;
    font-family: var(--body-font);
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
    transition: all 0.3s ease;
    box-shadow: 0 8px 20px rgba(44, 82, 130, 0.25);
}

.wedding-contact:hover {
    background-color: #4A5568;
    transform: translateY(-2px);
    box-shadow: 0 12px 25px rgba(44, 82, 130, 0.35);
}

.wedding-contact .button-arrow {
    margin-left: 0.8rem;
    transition: transform 0.3s ease;
}

.wedding-contact:hover .button-arrow {
    transform: translateX(5px);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design */
@media screen and (max-width: 1024px) {
    .testimonials-grid {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .season-flowers {
        grid-template-columns: repeat(3, 1fr);
        padding: 0 1rem;
    }

    .timeline {
        flex-wrap: wrap;
        gap: 3rem;
    }

    .timeline-item {
        flex: 1 1 40%;
    }
}

@media screen and (max-width: 768px) {
    .service-container {
        padding: 0 20px;
        margin: 0 auto;
    }

    .service-description {
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 2rem;
        margin: 2rem 0;
    }

    .services-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        margin: 2rem -20px;
        padding: 0 20px;
    }

    .service-card {
        padding: 1.5rem;
        margin: 0;
        border-radius: 10px;
        box-shadow: 0 2px 10px rgba(0,0,0,0.05);
    }

    .service-header {
        margin: 2rem -20px;
        padding: 3rem 20px;
        border-radius: 0;
    }

    .seasonal-section {
        padding: 3rem 1rem;
        margin: 2rem -20px;
        border-radius: 0;
    }

    .seasonal-section h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .seasons-tabs {
        flex-wrap: wrap;
        justify-content: center;
        gap: 0.5rem;
        padding: 0 1rem;
    }

    .season-tab {
        padding: 0.6rem 1.2rem;
        font-size: 0.9rem;
        flex: 0 1 auto;
    }

    .season-content {
        padding: 1rem;
    }

    .season-flowers {
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
        padding: 0;
    }

    .flower-item {
        padding: 1rem;
    }

    .testimonials-section {
        padding: 3rem 1rem;
        margin: 2rem -20px;
    }

    .testimonials-section h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .testimonials-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
        padding: 0;
    }

    .testimonial-card {
        padding: 1.5rem;
    }

    .testimonial-content {
        font-size: 0.95rem;
        line-height: 1.6;
    }

    .testimonial-author {
        margin-top: 1rem;
    }

    .author-image {
        width: 50px;
        height: 50px;
    }

    .author-details h4 {
        font-size: 1rem;
    }

    .author-details p {
        font-size: 0.85rem;
    }

    .contact-section {
        margin: 4rem 0;
        padding: 4rem 1.5rem;
    }
    
    .contact-content h2 {
        font-size: 2rem;
    }
    
    .wedding-contact {
        padding: 1rem 2.5rem;
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .service-container {
        padding: 0 15px;
    }

    .service-description {
        padding: 1.5rem;
        margin: 1.5rem -15px;
        border-radius: 0;
    }

    .services-grid {
        margin: 1.5rem -15px;
        padding: 0 15px;
    }

    .service-card {
        padding: 1.25rem;
    }

    .seasonal-section {
        padding: 2rem 1rem;
        margin: 1.5rem -15px;
    }

    .season-flowers {
        grid-template-columns: 1fr;
        gap: 1rem;
        max-width: 300px;
        margin: 0 auto;
    }

    .seasons-tabs {
        padding: 0;
    }

    .season-tab {
        padding: 0.5rem 1rem;
        font-size: 0.85rem;
        min-width: 80px;
    }

    .testimonials-section {
        padding: 2rem 1rem;
        margin: 1.5rem -15px;
    }

    .testimonial-card {
        padding: 1.25rem;
    }
}