.contact-page {
    min-height: calc(100vh - 70px); /* Adjust for navbar height */
    padding: 80px 0 0 0; /* Top padding matches navbar height */
    background-color: white;
}

.contact-container {
    max-width: 1400px;
    margin: 4rem auto;
    padding: 0 2rem; 
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
    animation: fadeIn 1s ease-out;
    box-sizing: border-box;
}

/* Left side - Contact Info */
.contact-info {
    padding-right: 2rem;
}

.contact-info h1 {
    font-family: var(--heading-font);
    font-size: clamp(3rem, 5vw, 4rem);
    color: var(--text-color);
    margin-bottom: 0.5rem;
    font-weight: 300;
}

.contact-info h2 {
    font-family: var(--accent-font);
    font-size: clamp(1.2rem, 2vw, 1.5rem);
    color: var(--text-color);
    font-weight: 300;
    font-style: italic;
    margin-bottom: 2rem;
    opacity: 0.9;
}

.info-content {
    margin-top: 2rem;
}

.info-content p {
    font-family: var(--body-font);
    font-size: 1.1rem;
    line-height: 1.8;
    color: var(--text-color);
    margin-bottom: 2rem;
}

.contact-details {
    margin-top: 3rem;
}

.locations {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.location {
    border-left: 2px solid var(--primary-blue);
    padding-left: 1rem;
}

.location h3 {
    font-family: var(--accent-font);
    font-size: 1.1rem;
    margin-bottom: 0.3rem;
    color: var(--text-color);
}

.location p {
    color: var(--text-color);
    margin: 0;
    font-size: 0.95rem;
}

.detail-item {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin-bottom: 2rem;
}

.detail-item .icon {
    font-size: 1.5rem;
}

.detail-item h3 {
    font-family: var(--accent-font);
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: var(--text-color);
}

.detail-item p {
    color: var(--text-color);
    margin: 0;
}

/* Right side - Contact Form */
.contact-form {
    background-color: var(--light-blue);
    padding: 3rem;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0,0,0,0.05);
    margin-top: 20px;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-family: var(--body-font);
    color: var(--text-color);
}

.form-group textarea {
    resize: none; /* Prevents resizing */
    height: 100px; /* Fixed height */
    overflow-y: auto; /* Adds vertical scroll when needed */
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 8px;
    font-family: var(--body-font);
    font-size: 1rem;
    background-color: white;
    transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
    outline: none;
    border-color: var(--dark-blue);
}

/* Optional: Style the scrollbar for webkit browsers */
.form-group textarea::-webkit-scrollbar {
    width: 8px;
}

.form-group textarea::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
}

.form-group textarea::-webkit-scrollbar-thumb {
    background: var(--dark-blue);
    border-radius: 4px;
}

.form-group textarea::-webkit-scrollbar-thumb:hover {
    background: var(--primary-blue);
}

.submit-button {
    width: 100%;
    padding: 1rem;
    background-color: var(--dark-blue);
    color: white;
    border: none;
    border-radius: 30px;
    font-family: var(--body-font);
    font-size: 1.1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.submit-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Mobile Responsive */
@media screen and (max-width: 768px) {
    .contact-page {
        padding: 100px 0 2rem 0; /* Remove horizontal padding */
        width: 100%;
    }

    .contact-container {
        grid-template-columns: 1fr;
        gap: 2rem;
        padding: 0 1rem;
        width: 100%;
        margin: 0;
    }

    .contact-info {
        padding-right: 0;
    }

    /* Form specific mobile styles */
    .contact-form {
        padding: 1.5rem;
        width: auto; /* Let it fill container */
        margin: 0 1rem;
        box-sizing: border-box;
    }

    .form-group {
        width: 100%;
        margin-bottom: 1.2rem;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        width: 100%;
        box-sizing: border-box;
    }

    .form-group label {
        font-size: 0.95rem;
        margin-bottom: 0.4rem;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        padding: 0.7rem;
        font-size: 1rem;
        -webkit-appearance: none; /* Fixes iOS styling issues */
        appearance: none;
        border-radius: 8px;
    }

    .form-group textarea {
        height: 120px; /* Slightly smaller on mobile */
    }

    /* Make the select dropdown more touch-friendly */
    .form-group select {
        background: white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23667eea' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
        background-size: 8px 10px;
        padding-right: 2rem;
    }

    /* Make the submit button more touch-friendly */
    .submit-button {
        padding: 0.9rem;
        margin-top: 1rem;
        font-size: 1rem;
        min-height: 48px; /* Minimum touch target size */
    }

    /* Fix iOS input styles */
    input[type="text"],
    input[type="email"],
    input[type="tel"],
    textarea {
        -webkit-appearance: none;
        appearance: none;
    }
}

/* Additional styles for very small screens */
@media screen and (max-width: 375px) {
    .contact-container {
        padding: 0 0.5rem;
    }

    .contact-form {
        padding: 1rem;
        margin: 0 0.5rem;
    }

    .form-group input,
    .form-group select,
    .form-group textarea {
        font-size: 16px; /* Prevents iOS zoom on focus */
    }
}

.status-message {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    margin: 0;
    border-radius: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.status-message.success {
    background-color: #d4edda;
    color: #155724;
    border-bottom: 1px solid #c3e6cb;
}

.status-message.error {
    background-color: #f8d7da;
    color: #721c24;
    border-bottom: 1px solid #f5c6cb;
}

.status-message.loading {
    background-color: #e2e3e5;
    color: #383d41;
    border-bottom: 1px solid #d6d8db;
}

.close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: inherit;
}

.timer-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: rgba(0, 0, 0, 0.1);
    animation: timer 10s linear forwards;
    width: 100%;
}

@keyframes timer {
    from {
        width: 100%;
    }
    to {
        width: 0;
    }
}