.navbar {
    background-color: white;
    padding: 1rem 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.navbar-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo-container {
    margin-bottom: 1rem;
    text-align: center;
}

.navbar-logo {
    text-decoration: none;
    color: var(--text-color);
}

.navbar-logo h1 {
    font-family: var(--heading-font);
    font-size: 2rem;
    font-weight: 400;
    letter-spacing: 3px;
    margin: 0;
}

.navbar-logo p {
    font-family: var(--body-font);
    font-size: 0.9rem;
    letter-spacing: 2px;
    margin: 0;
    color: #666;
}

.nav-menu {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.nav-link {
    text-decoration: none;
    color: var(--text-color);
    font-size: 0.9rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 0.5rem 0;
    position: relative;
    transition: color 0.3s ease;
}

.nav-link::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    bottom: 0;
    left: 50%;
    background-color: var(--dark-blue);
    transition: all 0.3s ease;
    transform: translateX(-50%);
}

.nav-link:hover::after {
    width: 100%;
}

.cart-icon {
    font-size: 1.2rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-count {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: var(--dark-blue);
    color: white;
    font-size: 0.7rem;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-menu-icon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 2rem;
    top: 1.5rem;
    color: var(--text-color);
}

@media screen and (max-width: 768px) {
    .navbar {
        padding: 1rem 0;
    }

    .mobile-menu-icon {
        display: block;
    }

    .nav-menu {
        display: none;
        width: 100%;
        background-color: white;
        padding: 1rem 0;
        position: absolute;
        top: 100%;
        left: 0;
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    .nav-menu.active {
        display: flex;
    }

    .nav-link {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
    }

    .nav-link::after {
        display: none;
    }

    .cart-icon {
        width: auto; /* Override the full width from nav-link */
        padding: 1rem;
        margin: 0;
        position: relative;
    }

    .cart-count {
        top: 4px;
        right: -4px;
    }

    .navbar-container {
        padding: 0 1rem;
    }

    .logo-container {
        margin-bottom: 0;
        padding: 0.5rem 0;
    }

    .navbar-logo h1 {
        font-size: 1.8rem;
    }

    .navbar-logo p {
        font-size: 0.8rem;
    }
}

/* Add smooth transition for menu */
@media screen and (max-width: 768px) {
    .nav-menu {
        transition: all 0.3s ease-in-out;
        opacity: 0;
        visibility: hidden;
        height: 0;
        overflow: hidden;
    }

    .nav-menu.active {
        opacity: 1;
        visibility: visible;
        height: auto;
        padding: 1rem 0;
    }
}