.checkout-form-container {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    max-width: 1200px;
    margin: 40px auto 40px;
    padding: 20px;
}

/* Order Summary (Left Side) */
.order-summary {
    flex: 0 0 45%;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    align-self: flex-start;
    position: sticky;
    top: 120px;
}

/* Payment Form (Right Side) */
.payment-form {
    flex: 0 0 50%;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form Fields */
.form-row {
    margin-bottom: 15px;
}

/* Two inputs in one row */
.form-row.two-cols {
    display: flex;
    gap: 15px;
}

.form-row.two-cols input {
    width: calc(50% - 7.5px); /* Half width minus half the gap */
}

.form-row input, 
.form-row textarea {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
}

/* Payment Section */
.payment-details {
    margin-top: 30px;
}

.payment-details .form-row {
    margin-bottom: 15px;
}

.payment-details label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #333;
}

.payment-details .two-cols {
    display: flex;
    gap: 15px;
}

.payment-details .two-cols > div {
    flex: 1;
}

.StripeElement {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
}

/* Submit Button */
.submit-button {
    width: 100%;
    padding: 15px;
    background-color: #0a4d3c;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.2s;
}

.submit-button:hover {
    background-color: #083d30;
}

@media (max-width: 768px) {
    .checkout-form-container {
        flex-direction: column;
        gap: 20px;
        padding: 15px;
        width: 110%;
        box-sizing: border-box;
    }

    .order-summary,
    .payment-form {
        flex: none;
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
        margin: 0;
    }

    .order-summary {
        position: static;
        margin-bottom: 20px;
    }

    .order-item {
        padding: 10px 0;
    }

    .item-image {
        width: 80px;
        height: 80px;
        margin-right: 15px;
    }

    .item-details h4,
    .item-price,
    .item-quantity {
        text-align: left;
    }

    .order-total {
        padding: 15px 0;
        margin-top: 15px;
    }

    .StripeElement {
        width: 100%;
        box-sizing: border-box;
        padding: 15px;
    }

    .payment-details .form-row {
        width: 100%;
        margin-bottom: 20px;
    }

    .payment-details .form-row:first-child {
        width: 100%;
    }

    .payment-details .form-row.two-cols {
        display: flex;
        gap: 15px;
        width: 100%;
    }

    .payment-details .form-row.two-cols > div {
        flex: 1;
        min-width: 0;
    }

    .StripeElement {
        width: 100%;
        padding: 8px;
        margin-top: 5px;
    }

    .StripeElement iframe {
        width: 100% !important;
        min-width: unset !important;
    }

    .payment-details label {
        display: block;
        margin-bottom: 8px;
    }
} 

/* Order Item Styling */
.order-item {
    display: flex;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
}

.item-image {
    width: 100px;
    height: 100px;
    margin-right: 20px;
    flex-shrink: 0;
    background: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
}

.item-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.placeholder-image {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    color: #666;
    font-size: 14px;
}

.item-details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.item-details h4 {
    margin: 0;
    font-size: 16px;
    color: #333;
}

.item-price {
    font-weight: 600;
    color: #0a4d3c;
    font-size: 16px;
}

.item-quantity {
    color: #666;
    font-size: 14px;
}

.order-total {
    margin-top: 20px;
    padding-top: 15px;
    border-top: 2px solid #eee;
    font-weight: bold;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
} 