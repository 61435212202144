.payment-failed-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
}

.payment-failed-content {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
}

.payment-failed-content h1 {
    color: #d32f2f;
    margin-bottom: 20px;
}

.payment-failed-content p {
    color: #666;
    margin-bottom: 20px;
    line-height: 1.6;
}

.retry-payment {
    background-color: #d32f2f;
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.retry-payment:hover {
    background-color: #c62828;
} 